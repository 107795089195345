// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

require("@popperjs/core")
require("jquery")

import "bootstrap"
import "bootstrap-datepicker"

// Import the specific modules you may need (Modal, Alert, etc)
import { Tooltip, Popover } from "bootstrap"

import React from 'react';
import { createRoot } from 'react-dom/client';
import StorageUsagePlot from './plots/storage-usage-plot';

// The stylesheet location we created earlier
//require("../stylesheets/application.scss")

global.$ = require('jquery')
window.jQuery = $;
window.$ = $;

// If you're using Turbolinks. Otherwise simply use: jQuery(function () {
document.addEventListener("turbolinks:load", () => {
    // Both of these are from the Bootstrap 5 docs
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function(tooltipTriggerEl) {
        return new Tooltip(tooltipTriggerEl)
    })

    var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
    var popoverList = popoverTriggerList.map(function(popoverTriggerEl) {
        return new Popover(popoverTriggerEl)
    })
})

Rails.start()
Turbolinks.start()
ActiveStorage.start()


document.addEventListener('DOMContentLoaded', () => {
    const container = document.getElementById('storage-usage-plot-root');
    if (container) {
        const root = createRoot(container);
        root.render(<StorageUsagePlot storage={$(container).data("storage-id")} />);
    }
});

$(document).ready(function() {

  $('.datepicker').datepicker({
      format: 'dd.mm.yyyy',
      startDate: '-1m',
      autoclose: true,
      closeOnDateSelect: true
  });

});
