import React from 'react';
import {Plot, newTable, NINETEEN_EIGHTY_FOUR, timeFormatter} from '@influxdata/giraffe';

class StorageUsagePlot extends React.Component {

  constructor(props) {
    super(props);
    this.state = { table: null };

  }

  componentDidMount() {
    fetch(`/storages/${this.props.storage}/datapoints`).then(
      (res) => res.json()).then(
      (data) => this.setData(data)
    );
  }

  translateName(name) {
    if (name == "current")
      return "Strom (A)";
    if (name == "voltage")
      return "Spannung (V)";
    if (name == "temperature")
      return "Temperatur (°C)";
  }

  setData(data) {
    const table = newTable(data.length)
      .addColumn('Zeit', 'dateTime:RFC3339', 'time', data.map((elem) => elem["ts"]*1000))
      .addColumn('Wert', 'double', 'number', data.map((elem) => elem["value"]))
      .addColumn('Typ', 'double', 'number', data.map((elem) => this.translateName(elem["field"])))
    this.setState({ table: table });
  }

  render() {
    const style = {
        width: "calc(70vw - 20px)",
        height: "calc(70vh - 20px)",
        margin: "40px",
      };

      const lineLayer = {
        type: "line",
        x: "Zeit",
        y: "Wert",
        colors: NINETEEN_EIGHTY_FOUR,
        legendHide: false,
        fill: ["Typ"],
        includeXDomainZoom: true
      };
      const table = this.state.table;

      const config = {
        table,
        gridColor: "#64686d",
        axisColor: "#64686d",
        gridOpacity: 0.6,
        xAxisLabel: "Zeit",
        yAxisLabel: "Wert",
        valueFormatters: {
          Wert: (num) => num.toFixed(2),
          Zeit: timeFormatter({ timeZone: 'Europe/Berlin', format: 'HH:mm:ss' })
        },
        staticLegend: {
          backgroundColor: "unset",
          border: "none",
          orientationThreshold: 2
        },
        layers: [lineLayer],
      };

      const SimplePlot = React.createElement(Plot, {config}, null);
      return React.createElement('div', {style}, SimplePlot);
    }
}

export default StorageUsagePlot;
